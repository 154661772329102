import { observer } from "mobx-react-lite";
import { useEffect, useState, useRef } from "react";
import { useStore } from "store";
import { Link } from "components/basic";
import BoardCover from "components/boards/BoardCover";
import { getBoardUrl, getProfileUrl, URL, IMAGE_SIZES } from "const";
import Artwork from "components/Artwork";
import ElementStats from "components/Blog/BlogpostRenderer/Elements/ElementStats";

function RecentBoards() {
  const [boardsToShow, setBoardsToShow] = useState([]);

  const { feedPage, profilePage, boardPage, boardIndexPage } = useStore("/pages");
  const { isMobile } = useStore("/ui");

  const container = useRef();

  useEffect(() => {
    function loadBoards() {
      const boards = feedPage.getRecentBoards(4);
      setBoardsToShow(boards);
    }

    loadBoards();
  }, [feedPage]);

  const containerOnMouseEnter = () => container.current.classList.add("translate-x-[-14rem]");
  const containerOnMouseLeave = () => container.current.classList.remove("translate-x-[-14rem]");

  if (!boardsToShow.length) return null;

  return (
    <div className="relative mx-auto w-full max-w-[100vw] overflow-y-scroll rounded-6 bg-white-100 p-20 lg:overflow-hidden">
      <h3 className="sticky left-0 top-0 mb-20 text-25 font-semibold">New Boards</h3>
      <div ref={container} className="flex space-x-20 transition-transform duration-500">
        {boardsToShow.map((board, index) => (
          <div
            key={index}
            className="flex w-full max-w-[26rem] flex-shrink-0 flex-col"
            onMouseEnter={!isMobile && index === 3 ? containerOnMouseEnter : null}
            onMouseLeave={!isMobile && index === 3 ? containerOnMouseLeave : null}
          >
            <Link
              href={getBoardUrl(board.id, board.name)}
              beforeNav={() => boardPage.mount(board.id)}
              className="mb-7 inline-block max-w-[26rem] self-start truncate text-ellipsis text-15 font-semibold hover:text-new-blue-100"
              rel="canonical"
            >
              {board.name}
            </Link>
            <div className="mb-13 flex text-13">
              <span className="mr-3 flex-shrink-0">by</span>
              <Link
                className="truncate text-ellipsis pb-2 font-semibold hover:text-new-blue-100"
                href={getProfileUrl(board.owner.id)}
                beforeNav={() => profilePage.mount(board.owner.id)}
                rel="canonical"
              >
                {board.owner.name}
              </Link>
            </div>
            <BoardCover className="h-[26rem] w-[26rem]">
              <Artwork
                url={getBoardUrl(board.id, board.name)}
                beforeNav={() => boardPage.mount(board.id)}
                artwork={board.previewArtwork || board.cover}
                sizes={IMAGE_SIZES.SimilarArtwork}
                style={{ WebkitUserDrag: "none" }}
                rel="canonical"
              >
                <Artwork.Overlay alwaysVisible>
                  {({ Like }) => (
                    <div className="flex h-full w-full flex-col">
                      <div className="mr-auto mt-auto mt-auto items-center rounded-full bg-white-100/80 transition-colors hover:bg-white-100">
                        <Like likes={board.likes} minifiedSize={25} color="gray" />
                      </div>
                    </div>
                  )}
                </Artwork.Overlay>
              </Artwork>
            </BoardCover>
            <ElementStats
              styles={{ listStyles: "mt-10" }}
              stats={[
                { name: "amountOfArtworks", value: board.stats.artworks },
                { name: "amountOfComments", value: board.stats.comments },
                { name: "amountOfLikes", value: board.stats.amountOfLikes },
              ]}
              likes={board.likes}
              sourceUrl={getBoardUrl(board.id, board.name)}
            />
          </div>
        ))}
      </div>
      <Link
        href={URL.BOARD_INDEX}
        beforeNav={() => boardIndexPage.mount()}
        className="sticky bottom-0 left-0 mt-30 inline-flex w-full justify-center rounded-6 border border-white-900 py-9 text-20 text-gray-1000 drop-shadow-sm transition-colors hover:bg-new-blue-100 hover:text-white-100"
      >
        See all Boards
      </Link>
    </div>
  );
}

export default observer(RecentBoards);
