import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { useEffect } from "react";
import { useRouter } from "hooks/router";
import LoadableList from "components/LoadableList";
import BlogpostCard from "components/Blog/BlogpostCard";
import cn from "classnames";
import { BLOGPOST_CARD_TYPES, AUTH_MODAL_STATE, URL } from "const";
import RecentArtworks from "components/FeaturedPosts/RecentArtworks";
import RecentBoards from "components/FeaturedPosts/RecentBoards";
import { Icon } from "components/basic";
import PropTypes from "prop-types";
import BlogpostPlaceholder from "components/placeholders/BlogPlaceholders/BlogpostPlaceholder";
import { getPasswordResetToken, confirmEmail } from "api";
import { isUUID } from "utils";
import { setToken } from "components/layout/AppLayout/Header/AuthModal/RecoveryEndForm";

function FeaturingDynamic({ featuringType, content }) {
  switch (featuringType) {
    case "recent_artworks":
      return <RecentArtworks />;

    case "recent_boards":
      return <RecentBoards />;

    default: //no-op
  }

  return (
    <div
      className={cn(
        "flex h-[20rem] w-full items-center justify-center rounded-6 text-32 font-semibold uppercase tracking-wide text-white-100",
        featuringType === "daisy" && "bg-orange-100"
      )}
    >
      {content}
    </div>
  );
}

FeaturingDynamic.propTypes = {
  featuringType: PropTypes.string,
  content: PropTypes.string,
};

FeaturingDynamic.defaultProps = {
  featuringType: null,
  content: null,
};

function FeedPage() {
  const router = useRouter();

  const { mount, recentPosts, loadFeedWithFeatured, postsFeed, pinnedPosts } = useStore("/pages/feedPage");
  const { authModal } = useStore("/ui");
  const { currentUserProfile } = useStore("/profiles");
  const { user } = useStore();

  useEffect(() => {
    if (!router.isReady) return () => {};

    async function loadFeed() {
      try {
        await mount();
      } catch (e) {
        //eslint-disable-next-line
        console.log(e);
        // router.push(URL.ROOT);
      }
    }

    loadFeed();

    return () => {};
  }, [mount, router]);

  useEffect(() => {
    if (!(router.isReady && router.query.uuid && isUUID(router.query.uuid))) return;

    async function handlePasswordReset() {
      const { uuid } = router.query;
      try {
        setToken(await getPasswordResetToken(uuid));
        authModal.setState(AUTH_MODAL_STATE.NEW_PASSWORD);
        authModal.setOpen(true);
      } catch {
        // noop
      }
    }

    handlePasswordReset();
  }, [authModal, router.isReady, router.query]);

  useEffect(() => {
    if (
      !(
        router.isReady &&
        router.pathname === URL.ROOT &&
        router.query.verificationToken &&
        isUUID(router.query.verificationToken)
      )
    )
      return;

    async function handleEmailConfirmation() {
      const { verificationToken } = router.query;
      try {
        await confirmEmail(verificationToken);
        authModal.setState(AUTH_MODAL_STATE.EMAIL_CONFIRM);
        authModal.setOpen(true);
      } catch {
        authModal.setState(AUTH_MODAL_STATE.INVALID_CONFIRM_LINK);
        authModal.setOpen(true);
      }
    }

    handleEmailConfirmation();
  }, [authModal, router.isReady, router.query, router.pathname]);

  if (!postsFeed.length)
    return (
      <div className="mx-auto mt-20 w-full lg:m-0 xl:p-0">
        <BlogpostPlaceholder className="mb-20 w-full max-w-[100rem]" />
        <BlogpostPlaceholder className="mb-20 w-full max-w-[100rem]" />
        <BlogpostPlaceholder className="mb-20 w-full max-w-[100rem]" />
        <BlogpostPlaceholder className="mb-20 w-full max-w-[100rem]" />
      </div>
    );

  return (
    <div className="mx-auto mt-20 lg:m-0">
      {pinnedPosts.list?.length > 0 && (
        <div className="mb-20 flex max-w-[100rem] flex-wrap justify-center gap-x-10 gap-y-20">
          {pinnedPosts.list.map(item => (
            <BlogpostCard
              key={item.id}
              cardType={BLOGPOST_CARD_TYPES.VIEW_FULL}
              pinned
              editable={currentUserProfile?.id === item.owner.id || user.isAdmin}
              {...item}
            />
          ))}
        </div>
      )}
      <LoadableList
        global
        className="flex max-w-[100rem] flex-wrap justify-center gap-x-10 gap-y-20"
        loadMore={loadFeedWithFeatured}
        isMoreAvailable={recentPosts.haveMorePages}
      >
        {postsFeed.map((item, index) =>
          item.featuringType ? (
            <FeaturingDynamic key={index} {...item} />
          ) : (
            <BlogpostCard
              key={item.id}
              cardType={BLOGPOST_CARD_TYPES.VIEW_FULL}
              editable={currentUserProfile?.id === item.owner.id || user.isAdmin}
              removable={user.isAdmin}
              {...item}
            />
          )
        )}
      </LoadableList>
      {recentPosts.isLoading && (
        <div className="mx-auto my-20 h-50 w-50 animate-spin rounded-[50%] border-4 border-t-white-1000 duration-1000" />
      )}
      {recentPosts.isFullyLoaded && (
        <div className="mx-auto my-20 flex w-full max-w-[100rem] items-center justify-center sm:mb-0">
          <div className="flex h-35 w-35 animate-appear items-center justify-center rounded-full bg-new-blue-100">
            <Icon
              icon="checkmark"
              className="stroke-round h-25 w-25 animate-stroke fill-transparent stroke-white-100"
            />
          </div>
          <p className="ml-10">You have reached the beginning of the feed!</p>
        </div>
      )}
    </div>
  );
}

export default observer(FeedPage);
